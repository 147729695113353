import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import firebase from 'firebase/app';
import 'firebase/auth'
import { toastController } from '@ionic/vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/Users'
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('@/views/SignIn.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/folder/:id',
    component: () => import('@/views/Folder.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Submissions/:id',
    component: () => import('@/views/Submissions.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Users',
    component: () => import('@/views/Users.vue'),
    meta: {
      requiresAuth: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && (!currentUser || currentUser === null)) {
    next('sign-in')
  } else {
    next()
  }
});

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    user.getIdTokenResult().then((idTokenResult) => {
      // Confirm the user is an Admin.
      if (idTokenResult.claims.admin) {
        // Show admin UI.
        router.replace("/");
      } else {
        firebase.auth().signOut().then(() => {
          toastController
            .create({
              message: "You are not an Admin.",
              duration: 2000,
              cssClass: "toast-center-text",
            })
            .then((toast) => toast.present());
        });
      }
    })
      .catch((error) => {
        console.log(error);
      });

  } else if (router.currentRoute.value.meta.requiresAuth) {
    router.replace("/sign-in");
  }
});


export default router;
