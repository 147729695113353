export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyA7nCFSuCpIudFTkcALVC4aHSRjRw_nN2Q",
        authDomain: "isugezt-8afba.firebaseapp.com",
        projectId: "isugezt-8afba",
        storageBucket: "isugezt-8afba.appspot.com",
        messagingSenderId: "82188559623",
        appId: "1:82188559623:web:3d612c2ea2f694943211a2",
        measurementId: "G-JE6FB6450D"
    }
};