<template>
  <IonApp>
    <ion-split-pane content-id="main" when="true">
      <ion-menu side="start" menu-id="top-menu" content-id="main" type="reveal">
        <ion-header>
          <ion-toolbar>
            <ion-title>
              <div>
                <img
                  style="width: 50%; margin-bottom: 0.2em; display: block"
                  src="/assets/logo.png"
                />
                <span style="display: block; margin-bottom: 0.5em">{{
                  displayName
                }}</span>
              </div>
            </ion-title>
          </ion-toolbar>
        </ion-header>

        <ion-content>
          <ion-list id="inbox-list">
            <ion-menu-toggle v-for="(p, i) in appPages" :key="i">
              <ion-item
                @click="selectedIndex = i"
                router-direction="root"
                :router-link="p.url"
                lines="none"
                detail="false"
                :class="{ selected: selectedIndex === i }"
              >
                <ion-icon
                  slot="start"
                  :ios="p.iosIcon"
                  :md="p.mdIcon"
                ></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle>
              <ion-item @click="logOut" lines="none" detail="false">
                <ion-icon
                  slot="start"
                  :ios="logOutOutline"
                  :md="logOutSharp"
                ></ion-icon>
                <ion-label>Log Out</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main"></ion-router-outlet>
    </ion-split-pane>
  </IonApp>
</template>

<script lang="ts">
import { environment } from "@/environment";
import firebase from "firebase/app";
require("firebase/functions");
require("firebase/auth");
require("firebase/firestore");

firebase.initializeApp(environment.firebase);
// firebase.functions().useEmulator("localhost", 5001);
// firebase.auth().useEmulator("http://localhost:9099/");
// firebase.firestore().useEmulator("localhost", 5002);

import {
  // IonImg,
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  // IonListHeader,
  IonMenu,
  IonMenuToggle,
  // IonNote,
  IonRouterOutlet,
  IonSplitPane,
  menuController,
  IonToolbar,
  IonTitle,
  IonHeader,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import {
  homeOutline,
  homeSharp,
  bulbOutline,
  bulbSharp,
  personOutline,
  personSharp,
  locationOutline,
  locationSharp,
  logOutOutline,
  logOutSharp,
} from "ionicons/icons";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonToolbar,
    IonTitle,
    IonHeader,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      // {
      //   title: "Home",
      //   url: "/folder/Home",
      //   iosIcon: homeOutline,
      //   mdIcon: homeSharp,
      // },
      {
        title: "Users",
        url: "/Users",
        iosIcon: personOutline,
        mdIcon: personSharp,
      },
      {
        title: "Submissions",
        url: "/Submissions/all",
        iosIcon: bulbOutline,
        mdIcon: bulbSharp,
      },
      // {
      //   title: "Locations",
      //   url: "/folder/Locations",
      //   iosIcon: locationOutline,
      //   mdIcon: locationSharp,
      // },
    ];

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
    const displayName = ref<string>("Your Name");
    const isLoggedIn = ref<boolean>(false);

    const route = useRoute();

    console.log(menuController.getMenus());
    menuController.enable(true, "top-menu");
    menuController.open("top-menu");
    console.log(menuController.isEnabled("top-menu"));
    console.log(menuController.isOpen("top-menu"));
    console.log(menuController.toggle("top-menu"));

    firebase.auth().onAuthStateChanged(function (user) {
      console.log("Checking auth:" + user);
      console.log(user);
      // isLoggedIn.value = user !== null;
      if (user && user.displayName) {
        displayName.value = user.displayName;
      }
    });
    const currentUser = firebase.auth().currentUser;
    // isLoggedIn.value = currentUser !== null;
    if (currentUser !== null) {
      if (currentUser.displayName != null) {
        displayName.value = currentUser.displayName;
      }
    }

    return {
      displayName,
      selectedIndex,
      appPages,
      homeOutline,
      homeSharp,
      bulbOutline,
      bulbSharp,
      personOutline,
      personSharp,
      locationOutline,
      locationSharp,
      logOutOutline,
      logOutSharp,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
      menuController,
      isLoggedIn,
    };
  },
  methods: {
    logOut() {
      firebase.auth().signOut();
    },
  },
});
</script>

<style scoped>
ion-split-pane {
  --side-min-width: 300px;
  --side-width: 300px;
}

ion-menu ion-content {
  --background: var(
    --ion-item-background,
    var(--ion-background-color, #fff)
  ) !important;
}
ion-menu {
  --min-width: 300px;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>