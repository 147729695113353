
import { environment } from "@/environment";
import firebase from "firebase/app";
require("firebase/functions");
require("firebase/auth");
require("firebase/firestore");

firebase.initializeApp(environment.firebase);
// firebase.functions().useEmulator("localhost", 5001);
// firebase.auth().useEmulator("http://localhost:9099/");
// firebase.firestore().useEmulator("localhost", 5002);

import {
  // IonImg,
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  // IonListHeader,
  IonMenu,
  IonMenuToggle,
  // IonNote,
  IonRouterOutlet,
  IonSplitPane,
  menuController,
  IonToolbar,
  IonTitle,
  IonHeader,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import {
  homeOutline,
  homeSharp,
  bulbOutline,
  bulbSharp,
  personOutline,
  personSharp,
  locationOutline,
  locationSharp,
  logOutOutline,
  logOutSharp,
} from "ionicons/icons";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonToolbar,
    IonTitle,
    IonHeader,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      // {
      //   title: "Home",
      //   url: "/folder/Home",
      //   iosIcon: homeOutline,
      //   mdIcon: homeSharp,
      // },
      {
        title: "Users",
        url: "/Users",
        iosIcon: personOutline,
        mdIcon: personSharp,
      },
      {
        title: "Submissions",
        url: "/Submissions/all",
        iosIcon: bulbOutline,
        mdIcon: bulbSharp,
      },
      // {
      //   title: "Locations",
      //   url: "/folder/Locations",
      //   iosIcon: locationOutline,
      //   mdIcon: locationSharp,
      // },
    ];

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
    const displayName = ref<string>("Your Name");
    const isLoggedIn = ref<boolean>(false);

    const route = useRoute();

    console.log(menuController.getMenus());
    menuController.enable(true, "top-menu");
    menuController.open("top-menu");
    console.log(menuController.isEnabled("top-menu"));
    console.log(menuController.isOpen("top-menu"));
    console.log(menuController.toggle("top-menu"));

    firebase.auth().onAuthStateChanged(function (user) {
      console.log("Checking auth:" + user);
      console.log(user);
      // isLoggedIn.value = user !== null;
      if (user && user.displayName) {
        displayName.value = user.displayName;
      }
    });
    const currentUser = firebase.auth().currentUser;
    // isLoggedIn.value = currentUser !== null;
    if (currentUser !== null) {
      if (currentUser.displayName != null) {
        displayName.value = currentUser.displayName;
      }
    }

    return {
      displayName,
      selectedIndex,
      appPages,
      homeOutline,
      homeSharp,
      bulbOutline,
      bulbSharp,
      personOutline,
      personSharp,
      locationOutline,
      locationSharp,
      logOutOutline,
      logOutSharp,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
      menuController,
      isLoggedIn,
    };
  },
  methods: {
    logOut() {
      firebase.auth().signOut();
    },
  },
});
